
    export default {
        data() {
            return {
                goingDown:false
            }
        },
        computed:{
            displayMode(){
                return this.$store.state.layout.displayMode;
            },
            fillColor(){
                return this.displayMode == 'dark' ? "#ffffff" : "#000000";
            },
            top(){
                return this.$store.state.scroll.top;
            }
        },
        watch : {
            displayMode(){
                console.log("Logo detect displayMode change");
            },
            fillColor(){
                console.log("Logo detect fillColor change");
            },
            top(t,f){
                this.goingDown = t > f;
            }
        },
        mounted() {
            this.$nextTick(()=>{
                this.$forceUpdate();
                setTimeout(() => {
                    this.$forceUpdate();
                }, 2000);
            })
        },
    }
